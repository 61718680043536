import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store'; // Import the Redux store
import App from './App';

// Use createRoot from "react-dom/client" instead of "react-dom"
import { createRoot } from 'react-dom/client';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);