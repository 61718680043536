import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import loginReducer from './reducers/login';
import usersReducer from './reducers/users';
import bannerReducer from './reducers/banner';
import noteReducer from './reducers/note';

// Define initial state for changeState
const initialState = {
  sidebarShow: true,
  // sidebarUnfoldable: true,
};

// Create the slice for changeState
const changeStateSlice = createSlice({
  name: 'changeState',
  initialState,
  reducers: {
    set: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Extract the actions from changeStateSlice
export const { set } = changeStateSlice.actions;

// Combine changeStateSlice.reducer with other reducers into a root reducer
const rootReducer = combineReducers({
  login: loginReducer,
  users: usersReducer,
  bannerInfo: bannerReducer,
  changeState: changeStateSlice.reducer,
  notes: noteReducer,
});

// Configure the store with the root reducer and middleware
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
