import React from 'react';
import config from '../oktaConfig';

const AuthorizationFlow = () => {
  const authorizeUrl = `${config.okta.issuer}/v1/authorize?client_id=${config.okta.clientId}&response_type=code&scope=${config.okta.scopes.join('+')}&state=blah&prompt=login&redirect_uri=${config.okta.redirectUri}`;

  return (
    <div>
      <h1>Authorization Flow</h1>
      <a href={authorizeUrl}>Authorize with Okta</a>
    </div>
  );
};

export default AuthorizationFlow;