const oktaConfig = {
  okta:{
  clientId: '0oaa006x5yn8OYQ1k1d7',
  redirectUri: window.location.origin+`/authorization-code/callback`,
  issuer: `https://mylogin-itg.hpe.com/oauth2/aus4dauk4l8LnWiTn1d7`,  
  scopes: ["openid", "profile", "email","offline_access"],
  pkce: true,
},
issuer: `https://mylogin-itg.hpe.com/oauth2/aus4dauk4l8LnWiTn1d7`,  
};

export default oktaConfig;


