// redux slice for login screen
import {createSlice} from '@reduxjs/toolkit';
import {validateUser} from '../apiCalls/login';

const initialState = {
  isValidatingUser: null,
  isUserValid: null,
  isLoggedIn: null,
  loginData: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetUserValidationState: (state) => {
      state.isUserValid = false;
    },
    resetLoginState: (state) => {
      state.isValidatingUser = null;
      state.isUserValid = null;
      state.isLoggedIn = null;
      state.loginData = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(validateUser.pending, (state) => {
      state.isValidatingUser = true;
      state.isLoggedIn = false;
    });
    builder.addCase(validateUser.fulfilled, (state, action) => {
      if (action.payload instanceof Error) {
        state.loginData = 'An error occurred while making the request. Please check your network connection and try again.';
      } else if(action.payload.status === 405) {
        state.isValidatingUser = false;
        state.isUserValid = false;
        state.isLoggedIn = false;
        state.loginData = 'some thing went wrong please try again'
      } else {
        state.isValidatingUser = true;
        state.isUserValid = true;
        state.isLoggedIn = true;
        state.loginData = action.payload.message ? action.payload.message : action.payload;
        
      }
    
    });
    builder.addCase(validateUser.rejected, (state) => {
      state.isValidatingUser = false;
      state.isUserValid = false;
      state.isLoggedIn = false;
    });
    

  },
});

export const {resetUserValidationState, resetLoginState} = loginSlice.actions;

export default loginSlice.reducer;
