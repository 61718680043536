import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import oktaAuth from './oktaAuth';
import Logout from './views/pages/login/Logout';
import AuthorizationFlow from './components/AuthorizationFlow';
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const CreateBanner = React.lazy(() => import('./views/menu/banner/create-banner/index'))

class App extends Component {
  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (oktaAuth, originalUri) => {
      console.log('okta auth restore original uri', oktaAuth, originalUri);      
      var userId= oktaAuth.authStateManager._authState.accessToken.claims.sub;
      if(localStorage.getItem("LoginId") === userId) 
       {
        window.location.href = '/dashboard';
      }
      else{
        Logout(oktaAuth)

      }        
    };
  }

  render() {
    return (

      <BrowserRouter>      
        <Suspense fallback={loading}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />

              <Route path="/authorization-code/callback" element={<LoginCallback />} />
              <Route path="/authorization-flow" component={AuthorizationFlow} />
            
              <Route path="*" name="Home" element={<DefaultLayout />} />
              <Route exact path="/login/callback" component={LoginCallback} />              
              <Route exact path="/create-banner" name="Create Banner" element={<CreateBanner />} />


            </Routes>
          </Security>
        </Suspense>      
      </BrowserRouter>
    )
  }
}

export default App
