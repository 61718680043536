
const Logout = (oktaAuth) => {
    oktaAuth.signOut()
      .then(() => {
         localStorage.setItem("redirectedUrl",true);
        console.log("logout success")
      })
      .catch(err => {
        console.error('Logout error:', err);
      });
  };

 export default Logout;